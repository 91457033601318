import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { Typography } from "@mui/material"

export default class TextInput extends Component {
    constructor() {
        super()
        autobind(TextInput, this)
    }
    handleChange(event) {
        const { onChange, input } = this.props
        const { target } = event
        if (this.props.type === "number" && isNaN(target.value)) {
            return
        }
        onChange(event)

        if (input?.label?.toLowerCase() === "Nombres y Apellidos".toLowerCase()) {
            const newEvent = new CustomEvent("userbyid", { detail: { name: target.value } })
            window.dispatchEvent(newEvent)
        }
    }
    render() {
        const { value, label, name, type, disabled, options, required, onBlur, autoFocus, onKeyDown, positiveOnly, error, errorMsge, width, backgroundColor, min } = this.props
        const newValue = value < 0 ? "0" : value ? value : value === 0 ? "0" : value
        const currentValue = value ? value : value === 0 ? "0" : value
        return (
            <TextField
                value={positiveOnly ? newValue : currentValue}
                disabled={disabled}
                onChange={this.handleChange}
                label={<Typography sx={{ fontWeight: required ? 600 : 400 }} color={"black"}>{`${label}${required ? "*" : ""}`}</Typography>}
                name={String(name)}
                variant="outlined"
                fullWidth
                style={{
                    margin: "12px 0",
                    width: width || '100%',
                    borderRadius: "5px",
                    boxShadow: `4px 4px 6px ${backgroundColor}`
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                type={type}
                rows={options.rows}
                onBlur={onBlur}
                autoFocus={autoFocus}
                multiline={options.multiline || false}
                placeholder={options.placeholder}
                onKeyDown={onKeyDown || null}
                helperText={error ? errorMsge : null}
                InputProps={{
                    inputProps: {
                        ...(min !== undefined ? { min: min } : {}), // Si min está definido, lo incluimos
                    }
                }}
            />
        )
    }
}

TextInput.propTypes = {

}


TextInput.defaultProps = {
    options: {},
    width: "100%",
}