import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'

const style = () => ({
  icon: {
    transition: 'all 0.2s linear'
  },
  open: {
    transform: 'rotate(90deg)'
  }
})

class ToggleIcon extends Component {

  render() {
    const { classes, open } = this.props
    return (
      <KeyboardArrowRight className={`${classes.icon} ${open ? classes.open : ""}`} />
    )
  }
}

export default withStyles(style)(ToggleIcon)