import React, { Component, useState } from 'react'
import { Dialog, DialogContent, IconButton, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import { Delete, KeyboardArrowLeft, KeyboardArrowRight, RotateRight } from '@material-ui/icons'
import { Badge, Box, Typography } from '@mui/material'
import useToggle from '../../Hooks/ToogleHook'
import { end_buttons, inline_space } from '../../Utils/defaultStyles'
import { completePath } from '../../Utils/functions'

const css = {
  images_container: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    cursor: 'pointer',
    left: 0,
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  },
  grid_images_container: {
    position: 'relative',
    display: 'flex',
    gap: 2,
    flexWrap: 'wrap'
  },
  grid_image: {
    cursor: 'pointer',
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  }

}

function MultipleImageContainer({ images, height, width, border, alt, align = "right", base_url = "", noOffset = false, grid = false, onRemove }) {

  const images_without_name = images.map(image => image.split("|&|")[0])
  const [openDialog, toggleDialog] = useToggle(false)
  const [page, setPage] = useState(0)
  const [rotation, setRotation] = useState(0)
  const actualImage = images_without_name[page]
  const hasImages = !!images_without_name.length

  function changePage(option) {
    if (option === "next") return setPage(p => (p + 1) % images_without_name.length)
    if (option === "prev") return setPage(p => (p - 1 + images_without_name.length) % images_without_name.length)
  }

  function openInPage(page) {
    setPage(page)
    toggleDialog()
  }

  function onRotate() {
    setRotation(r => (r + 90) % 360)
  }

  function asGrid() {
    return (
      <Box sx={css.grid_images_container} >
        {hasImages ? images_without_name.map((image, index) => {

          return (
            <Box sx={css.grid_image} style={{ borderRadius: border }} key={index}>
              <Box sx={{ maxWidth: width, maxHeight: height, height, overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
                <img src={completePath(image, base_url)} width={width} alt={alt} onClick={() => openInPage(index)} style={{ borderRadius: border }} />
              </Box>
            </Box>
          )
        }
        ) :
          <img src={'/noimage.png'} height={height} width={width} alt={alt} style={{ borderRadius: border }} />
        }
      </Box>
    )
  }

  function asStack() {
    return (
      <Box sx={css.images_container} style={{ height, width }}>
        {hasImages ? images_without_name.map((image, index) => {

          return (
            <Box sx={css.image} style={{ transform: noOffset ? "" : translate_value(index), borderRadius: border }} key={index}>
              <Badge badgeContent={index === badge_position ? images_without_name.length : 0} color="info">
                <Box sx={{ maxWidth: width, maxHeight: height, height, overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
                  <img src={completePath(image, base_url)} width={width} alt={alt} onClick={toggleDialog} style={{ borderRadius: border }} />
                </Box>
              </Badge>
            </Box>
          )
        }
        ) :
          <img src={'/noimage.png'} height={height} width={width} alt={alt} style={{ borderRadius: border }} />
        }
      </Box>
    )
  }

  const translate_value = (index) => align === "right" ? `translate(-${index * 18}px, 0)` : `translate(${index * 18}px, 0)`
  const badge_position = align === "right" ? 0 : images_without_name.length - 1
  return (
    <Box>
      <Box>
        {grid ? asGrid() : asStack()}
      </Box>
      <Dialog open={openDialog && !!images_without_name.length} onClose={toggleDialog} fullWidth maxWidth="sm">
        <DialogContent>
          <Box sx={end_buttons}>
            {!!onRemove &&
              <IconButton onClick={() => onRemove(page)}>
                <Delete />
              </IconButton>
            }
            <IconButton onClick={onRotate}>
              <RotateRight />
            </IconButton>
          </Box>
          <Box style={{ overflow: 'auto', maxWidth: 600, maxHeight: 600 }} >
            <img style={{ transform: `rotate(${rotation}deg)` }} src={completePath(actualImage, base_url)} alt={alt} width="100%" />
          </Box>
          <Box sx={inline_space}>
            <IconButton onClick={() => changePage("prev")}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography variant='subtitle2'>{`${page + 1}/${images_without_name.length}`}</Typography>
            <IconButton onClick={() => changePage("next")}>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default MultipleImageContainer