import instance2 from "../Utils/instance2"

export function getBranches(body) {
  return instance2.get('branches', { params: body })
}

export function getBranchIdentifiers(body) {
  return instance2.get('branches/identifiers', { params: body })
}

export function createBranchIdentifier(body) {
  return instance2.post('branches/identifiers', body)
}

export function changeActiveDate(body) {
  return instance2.put('users/active_date', body)
}

export function updateBranchIdentifier(body) {
  return instance2.put('branches/identifiers', body)
}

export function deleteBranchIdentifier(body) {
  return instance2.delete('branches/identifiers', { data: body })
}

export function createBranch(body) {
  return instance2.post('branches', body)
}

export function updateBranch(body) {
  return instance2.put('branches', body)
}

export function getBranchUsers(body) {
  return instance2.get('branches/users', { params: body })
}

export function getBranchCommittee(body) {
  return instance2.get('branches/committee', { params: body })
}

export function editBranchCommittee(body) {
  return instance2.put('branches/committee', body)
}

export function assignBranchUserCommitteeRank(body) {
  return instance2.put('branches/committee/rank', body)
}

export const getSettingsBranchDocuments = () => {
  return instance2({
    method: 'get',
    url: 'settings/branch_documents/get_category.php'
  })
}

export const getBranchTags = body => {
  return instance2({
    method: 'get',
    url: 'branches/tags',
    params: body
  })
}

export const createBranchTag = body => {
  return instance2({
    method: 'post',
    url: 'branches/tags',
    data: body
  })
}

export const editBranchTag = body => {
  return instance2({
    method: 'put',
    url: 'branches/tags',
    data: body
  })
}

export const deleteBranchTag = body => {
  return instance2({
    method: 'delete',
    url: 'branches/tags',
    data: body
  })
}

export const assignTag = body => {
  return instance2({
    method: 'post',
    url: 'branches/assign',
    data: body
  })
}

export const getSubBranches = body => {
  return instance2({
    method: 'get',
    url: 'subbranches/get.php',
    params: body
  })
}

export const createSubBranch = body => {
  return instance2({
    method: 'post',
    url: 'subbranches/create/form',
    data: body
  })
}

export function setBranchCertificate(body) {
  return instance2.post('branches/certificate', body)
}

export function lastAccidentDate(body) {
  return instance2.get('branches/last_accident_date', { params: body })
}

export function updateLastAccidentDate(body) {
  return instance2.put('branches/last_accident_date', body)
}

export function getHHFactor() {
  return instance2.get('branches/hh_factor')
}

export function updateHHFactor(body) {
  return instance2.put('branches/hh_factor', body)
}