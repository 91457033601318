import { CheckCircle, DateRange, Event, FormatAlignCenter, FormatListBulleted, FormatListNumbered, HelpOutline, ImageOutlined, LooksOneOutlined, PersonPin, TextFormat, InsertDriveFileOutlined, DescriptionOutlined } from '@material-ui/icons'
import { Box, Divider, Drawer, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material'
import { title_style } from '../../../../../../Utils/defaultStyles'
import { useState } from 'react'
import { CalculateOutlined, Engineering, Group, LocationOnOutlined } from '@mui/icons-material'


const css = {
	drawer: {
		padding: 4,
		minWidth: 250,
	},
	checkbox: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 2,

	}
}

export const field_types = [
	{ name: "Texto", value: "text", icon: TextFormat },
	{ name: "Selección de una opción", value: "select", icon: FormatListBulleted },
	{ name: "Selección múltiple", value: "multiselect", icon: FormatListNumbered },
	{ name: "Imágen", value: "img", icon: ImageOutlined },
	{ name: "Fecha", value: "date", icon: Event },
	{ name: "Número", value: "number", icon: LooksOneOutlined },
	{ name: "Texto Enriquecido ", value: "rte", icon: FormatAlignCenter },
	{ name: "RUT", value: "rut", icon: TextFormat },
	{ name: "Duración (periodo)", value: "duration", icon: DateRange },
	{ name: "Cumplimiento", value: "compliance", icon: CheckCircle },
	{ name: "IDS (Experimental)", value: "ids", icon: HelpOutline },
	{ name: "Geolocalización", value: "location", icon: LocationOnOutlined },
	{ name: "Colaborador", value: "users", icon: PersonPin },
	{ name: "Colaboradores", value: "multi_users", icon: Group },
	{ name: "Empresa Contratista", value: "contractor", icon: Engineering },
	{ name: "Empresas Contratistas", value: "multi_contractors", icon: Engineering },
	{ name: "Cálculo", value: "calc", icon: CalculateOutlined },
	{ name: "Documento", value: "document", icon: DescriptionOutlined }
]

export function getTypeName(value) {
	return field_types.find(f => f.value === value)?.name || ""
}

function FieldTypeDrawer({ open, onClose, onSubmit, withRequire }) {

	const [required, setRequired] = useState(true)

	function onClick(value) {
		onSubmit(value, withRequire && required)
	}

	return (
		<Drawer open={open} anchor='right' onClose={onClose}>
			<Box sx={css.drawer}>
				<Typography variant='h4' sx={title_style} >Selecciona tipo de pregunta</Typography>
				<Typography variant='subtitle1' sx={title_style}>Selecciona el tipo de pregunta que quieres crear</Typography>
				{field_types.map(field_type => {
					return (
						<Box key={field_type.value}>
							<ListItemButton onClick={() => onClick(field_type.value)}>
								<ListItemIcon>
									<field_type.icon />
								</ListItemIcon>
								<ListItemText primary={<Typography variant='subtitle1'>{field_type.name}</Typography>} />
							</ListItemButton>
							<Divider />
						</Box>
					)
				})}
				{withRequire && <Box sx={css.checkbox}>
					<Typography variant='subtitle1'>Campo Obligatorio</Typography>
					<Switch color="warning" checked={required} onChange={() => setRequired(!required)} />
				</Box>
				}
			</Box>
		</Drawer>
	)
}

export default FieldTypeDrawer