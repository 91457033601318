import { Box } from '@mui/material'
import BigTooltip from '../Tooltip/BigTooltip'
import CommitteeIcon from './CommitteeIcon'

function CommitteeDisclaimer() {
	return (
		<BigTooltip title="Pertenece al comité paritario">
			<Box>
				<CommitteeIcon color="celeste" />
			</Box>
		</BigTooltip>
	)
}

export default CommitteeDisclaimer