import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../Utils/autobind'

const style = () => ({
  tag: {
    borderRadius: 24,
    height: 16,
    width: 16,
    margin: 3,
    padding: 3,
    transition: 'width 0.2s linear, height 0.1s linear 0.2s',
    overflow: 'hidden',
    '& > *': {
      opacity: 0,
      transition: 'all 0.2s linear'
    },
    cursor: 'pointer'
  },
  open: {
    width: '100%',
    borderRadius: 24,
    height: 24,
    '& > *': {
      transition: 'all 0.2s linear 0.3s',
      color: 'white',
      fontWeight: 600,
      opacity: 1,
      lineHeight: 2,
      padding: '0 12px',
    }
  }
})
class InnerTag extends Component {
  constructor() {
    super()
    this.state = {
      openTag: false
    }
    addToggle(InnerTag, this, "tag")
    autobind(InnerTag, this)
  }

  render() {
    const { classes, tag } = this.props
    const { openTag } = this.state
    if (!tag) return null
    return (
      <div className={`${classes.tag} ${openTag ? classes.open : ""}`} style={{ background: tag.color }} onClick={this.handleOpenTag}>
        <Typography variant='subtitle1'>{tag.name}</Typography>
      </div>
    )
  }
}

export default withStyles(style)(InnerTag)