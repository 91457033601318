import React, { Component } from "react"
import moment from "moment"
import { KeyboardDatePicker } from '@material-ui/pickers/'
import { Typography } from "@mui/material"

const DateInput = React.forwardRef((props, ref) => {

    const handleChange = (value) => {
        const { onChange, name } = props
        const result = nullable ? (value?.format('YYYY-MM-DD') || null) : (value?.format('YYYY-MM-DD') || 'YYYY-MM-DD')
        const event = { target: { name, value: result } }
        onChange(event)
    }

    const { value, label, name, disabled, required, onBlur, minDate, maxDate, nullable = false } = props

    return (
        <KeyboardDatePicker
            // disableToolbar
            autoOk
            // disablePast
            label={<Typography sx={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>}
            name={name}
            fullWidth
            onBlur={onBlur}
            disabled={disabled}
            format="YYYY-MM-DD"
            inputVariant="outlined"
            value={nullable ? (value ? moment(value) : null) : (value || moment().format("YYYY-MM-DD"))}
            onChange={handleChange}
            style={{ margin: "12px 0" }}
            ref={ref}
            clearable={nullable}
        />
    )
})

export default DateInput