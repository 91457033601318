import React, { Component } from "react"
import moment from "moment"
import { KeyboardDateTimePicker } from '@material-ui/pickers/'
import autobind from "../../Utils/autobind"
import { Typography } from "@mui/material"

export default class DateTimeInput extends Component {
    constructor(props) {
        super(props)
        autobind(DateTimeInput, this)
    }
    handleChange(value) {
        const { onChange, name } = this.props
        const result = value?.format('YYYY-MM-DD HH:mm') || 'YYYY-MM-DD HH:mm'
        const event = { target: { name, value: result } }
        onChange(event)
    }
    render() {
        const { value, label, name, disabled, required } = this.props
        return (
            <KeyboardDateTimePicker
                autoOk
                label={<Typography sx={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>}
                name={name}
                fullWidth
                disabled={disabled}
                format="YYYY-MM-DD HH:mm"
                value={value || moment().format("YYYY-MM-DD")}
                onChange={this.handleChange}
                style={{ margin: "12px 0" }}
                inputVariant="outlined"
            />
        )
    }
}
