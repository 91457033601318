import React, { Component } from "react"
import { TextField, Typography } from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { getUserByRut } from '../../API/users'

export default class RutInput extends Component {
    constructor() {
        super()
        this.state = {
            valid: true,
            user: ""
        }
        autobind(RutInput, this)
    }

    componentDidMount() {
        const { value } = this.props
        if (value) {
            this.handleRutVerification(this.props.value)
        }
    }

    async verifyRut(rut) {
        const { setBlock } = this.props
        if (!rut) return
        const response = await getUserByRut({ rut })
        const { data: { info: user } } = response
        this.setState({ user })
        if (user === "Usuario no existe.") {
            const event = new CustomEvent("userbyid", { detail: { rut } })
            window.dispatchEvent(event)
            if (setBlock) setBlock(false)
        } else {
            const event = new CustomEvent("userbyid", { detail: user })
            window.dispatchEvent(event)
        }
    }

    formatRut(value) {
        const replacedValue = value.replaceAll(".", "").replace("-", "")
        if (replacedValue.length < 7) return replacedValue

        const cuerpo = replacedValue.slice(0, -1)
        const dv = replacedValue.slice(-1).toUpperCase()
        return cuerpo + "-" + dv
    }

    validateRut(rut) {
        const replacedValue = rut.replaceAll(".", "").replace("-", "")
        const cuerpo = replacedValue.slice(0, -1)
        const dv = replacedValue.slice(-1).toUpperCase()

        let suma = 0
        let multiplo = 2

        for (let i = 1; i <= cuerpo.length; i++) {
            let index = multiplo * replacedValue.charAt(cuerpo.length - i)
            suma += index
            multiplo = multiplo < 7 ? multiplo + 1 : 2
        }

        const dvEsperado = 11 - (suma % 11)
        let dvalue = parseInt(dv, 10)
        if (dv === 'K') dvalue = 10
        if (dv === '0') dvalue = 11

        return dvEsperado === dvalue
    }

    handleChange(event) {
        const { target } = event
        const { onChange } = this.props
        const { name, value: newValue } = target
        const formattedValue = this.formatRut(newValue)

        const newEvent = {
            target: {
                name,
                value: formattedValue,
            }
        }
        onChange(newEvent)

        this.handleRutVerification(formattedValue)
    }

    handleRutVerification(value) {
        const formattedRut = this.formatRut(value)
        const isValid = this.validateRut(formattedRut)
        const { setBlock } = this.props
        this.setState({ valid: isValid, user: "" }, async () => {

            if (setBlock) {
                setBlock(!isValid)
            }
            if (isValid) {
                await this.handleGetWorker(formattedRut)
                if (setBlock && !this.state.user) {
                    setBlock(false)

                }
            }
        })
    }

    async handleGetWorker() {
        const { value } = this.props
        const { setBlock } = this.props
        const response = await getUserByRut({ rut: value })
        const { data: { info: user } } = response
        this.setState({ user })
        if (user === "Usuario no existe") {
            const event = new CustomEvent("userbyid", { detail: { rut: value } })
            if (setBlock) setBlock(false)
            return window.dispatchEvent(event)
        }
        else {
            const event = new CustomEvent("userbyid", { detail: user })
            if (setBlock) {
                setBlock(true)
            }
            return window.dispatchEvent(event)
        }
    }

    render() {
        const { valid, user } = this.state
        const { value, label, name, type, disabled, options, required, onBlur, autoFocus } = this.props
        const isUserValid = typeof user !== "string"
        return (
            <>
                <TextField
                    value={value || ""}
                    disabled={disabled}
                    onChange={this.handleChange}
                    label={<Typography style={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>}
                    name={String(name)}
                    variant="outlined"
                    fullWidth
                    style={{ margin: "12px 0" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={type}
                    rows={options.rows}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                    multiline={options.multiline || false}
                    placeholder={options.placeholder}
                />
                {!valid && <Typography variant="caption" style={{ color: 'red' }}>Rut inválido</Typography>}
                {isUserValid && <div style={{ marginBottom: 12, marginTop: -12 }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 600, fontStyle: "italic" }}>
                        {user.name} - {user.position}
                    </Typography>
                </div>}
            </>
        )
    }
}

RutInput.propTypes = {

}


RutInput.defaultProps = {
    options: {}
}