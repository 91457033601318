import { Box, Checkbox, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import moment from 'moment'
import TextInput from '../Inputs/TextInput'
import RutInput from '../Inputs/RutInput'
import SelectInput from '../Inputs/SelectInput'
import NewMultiSelectInput from '../Inputs/NewMultiSelectInput'
import RTE from '../Inputs/RTE'
import ImageContainer from '../Containers/ImageContainer'
import DateTimeInput from '../Inputs/DateTimeInput'
import DateInput from '../Inputs/DateInput'
import PlainImageInput from '../Inputs/PlainImageInput'
import MultipleImageContainer from '../Containers/MultipleImageContainer'
import newTheme from '../../newTheme'

const css = {
	input: {
		'& > *': {
			margin: "0 !important"
		}
	},
	field: {
		marginBottom: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
	options: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2
	},
	option: {
		background: 'whitesmoke',
		padding: '6px 12px',
		borderRadius: 1
	},
	photo: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 1
	}
}

function getArray(value) {
	if (value === "") return []
	return JSON.parse(value)
}

function transofrmToExtraOptions(options) {
	if (!options) return []
	return options.map(option => {
		if (typeof option === "string") {
			return {
				label: option.split('&')[0],
				value: option.split('&')[0]
			}
		}
		return { label: option?.label || "", value: option?.label || "" }
	})
}

function TextField({ field, onChange, value, onBlur, finished, disabled }) {
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || "Sin respuesta"}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<TextInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function RutField({ field, onChange, value, onBlur, finished, disabled }) {
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || "Sin respuesta"}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<RutInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function SelectField({ field, onChange, value, onChangeAndSave, finished, updateField }) {
	const options = field?.options || []
	const selectedOption = options.find(option => option.label === value)
	const subquestions = selectedOption?.subquestions || []
	const selectedOptionPhoto = selectedOption?.photo || ""

	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{selectedOption ? selectedOption?.label : "Sin respuesta"}</Typography>
			{!!selectedOptionPhoto &&
				<Box sx={css.photo}>
					<ImageContainer src={selectedOptionPhoto} width={64} height={64} alt="FOTO" border="8px" />
				</Box>
			}
			<Box style={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <GeneralField field={subquestion} finished={finished} updateField={updateField} />)}
			</Box>
		</Box>
	)

	return (
		<Box sx={css.input}>
			<SelectInput value={value} onChange={onChangeAndSave} label="" options={transofrmToExtraOptions(options)} />
			{!!selectedOptionPhoto &&
				<Box sx={css.photo}>
					<ImageContainer src={selectedOptionPhoto} width={64} height={64} alt="FOTO" border="8px" />
				</Box>
			}
			<Box style={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <GeneralField field={subquestion} updateField={updateField} />)}
			</Box>
		</Box>
	)
}

function MultiField({ field, onChange, value = [], onBlur, finished, disabled }) {
	const options = field?.options || []
	const real_value = Array.isArray(value) ? value : getArray(value)


	if (finished) return (
		<Box sx={css.input}>
			<Box sx={css.options}>
				{!!real_value?.length ?
					real_value.map(val => <Typography sx={css.option} variant='subtitle1'>{val}</Typography>) :
					<Typography variant='subtitle1'>Sin respuesta</Typography>
				}
			</Box>
		</Box>
	)

	return (
		<Box sx={css.input}>
			<NewMultiSelectInput onBlur={onBlur} value={real_value} onChange={onChange} label="" options={transofrmToExtraOptions(options)} />
		</Box>
	)
}

function RTEField({ field, onChange, value, onBlur, finished }) {


	return (
		<Box style={{ maxWidth: '99%' }}>
			<RTE disabled={finished} onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>

	)
}

function ComplianceField({ field, onChange, value, onBlur, onChangeAndSave, finished }) {

	const [params, setParams] = useState(value ? { ...value } : {})

	useEffect(() => {
		const newValue = value ? JSON.parse(value) : {}
		setParams({ ...newValue })
	}, [value])

	function onChangeParams(event) {
		const { target } = event

		const new_value = value ? { ...params } : {}
		if (["total", "desfav"].includes(target.name)) {
			const value = target.value > 0 ? target.value : 0
			new_value[target.name] = value
		} else {
			new_value[target.name] = target.value
		}

		const new_event = {
			target: { value: JSON.stringify(new_value) }
		}
		setParams(new_value)
		onChange(new_event)
	}

	function toggleSolved() {
		const new_value = value ? { ...params } : {}
		new_value.solved = !new_value.solved
		const new_event = {
			target: { value: JSON.stringify(new_value) }
		}
		setParams(new_value)
		onChangeAndSave(new_event)
	}

	const ratio = params.total > 0 ? params.desfav / params.total : 0

	const calculated_compliance = (100 - (ratio) * 100)
	const partial_compliance = params.solved ? calculated_compliance : 0
	const final_compliance = params.solved ? 100 : calculated_compliance

	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{field.label}: <strong>{params.total}</strong></Typography>
			<Typography variant='subtitle1'>{field.compliance_case}: <strong>{params.desfav}</strong></Typography>
			{calculated_compliance < 100 && <Typography variant='subtitle1'>{field.compliance_observation}: <strong>{params.comment}</strong></Typography>}
			{calculated_compliance < 100 && <Typography variant='subtitle1'>Resuelto en el lugar: <strong>{params.solved ? "SI" : "NO"}</strong></Typography>}
			{calculated_compliance < 100 && <Typography variant='subtitle1'>Cumplimiento parcial: <strong>{partial_compliance.toFixed(1)}%</strong></Typography>}
			<Typography variant='subtitle1'>Cumplimiento final: <strong>{final_compliance.toFixed(1)}%</strong></Typography>
		</Box>
	)

	return (
		<>
			<Box sx={css.input}>
				<TextInput type="number" onBlur={onBlur} value={params.total} onChange={onChangeParams} label="" name="total" />
				<Typography variant='subtitle1'>{field.compliance_case}</Typography>
				<TextInput type="number" onBlur={onBlur} name="desfav" value={params.desfav} onChange={onChangeParams} label="" />
				{(final_compliance < 100 || (params.solved && partial_compliance < 100)) &&
					<Box sx={css.inline}>
						<Checkbox checked={params.solved || false} onClick={toggleSolved} />
						<Typography variant='subtitle1'>Se solucionó el incumplimiento en el lugar?</Typography>
					</Box>
				}
				{partial_compliance !== 0 && <Typography variant='subtitle1'><strong>Cumplimiento Parcial: {partial_compliance.toFixed(1)}%</strong></Typography>}
				<Typography variant='subtitle1'><strong>Cumplimiento Final: {final_compliance.toFixed(1)}%</strong></Typography>
				{(final_compliance < 100 || (params.solved && partial_compliance < 100)) &&
					<Box sx={css.input}>
						<Typography variant='subtitle1'>{field.compliance_observation}</Typography>
						<TextInput onBlur={onBlur} name="comment" value={params.comment} onChange={onChangeParams} label="" />
					</Box>
				}
			</Box>
		</>
	)
}

function ImageField({ field, onChange, value, onChangeAndSave, finished, disabled }) {

	const images = typeof value === "string" ? value.split("&#&") : ""
	const base_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
	if (finished) return (
		<Box sx={css.input}>
			{!!images.length ?
				<MultipleImageContainer
					border="6px"
					base_url={base_url}
					images={images}
					width={64}
					height={64}
					align='left'
				/>
				:
				<Typography variant='subtitle1'>Sin respuesta</Typography>
			}
		</Box>
	)

	return (
		<Box sx={css.input}>
			<PlainImageInput
				value={value}
				name={field.label}
				onChange={onChangeAndSave}
				label=""
				url={"/"}
			/>
		</Box>
	)
}

function PeriodField({ field, onChange, value, onChangeAndSave, disabled, finished }) {

	function processValue() {
		return value?.split("#") || [moment().format("YYYY-MM-DD hh:mm"), moment().format("YYYY-MM-DD hh:mm")]
	}

	function onChangePeriod(step) {
		return (event) => {
			const { target } = event
			const new_value_array = processValue()
			new_value_array[step] = target.value
			const new_value = new_value_array.join("#")
			const new_event = { target: { value: new_value } }
			onChangeAndSave(new_event)
		}
	}

	const parsed_value = processValue()

	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>Desde: {parsed_value[0]} - Hasta: {parsed_value[1]}</Typography>
		</Box>
	)

	return (
		<Box sx={{ ...css.input, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
			<Box sx={{ flexBasis: 250, flexGrow: 1 }}>
				<DateTimeInput onChange={onChangePeriod(0)} label="" value={parsed_value[0]} />
			</Box>
			<Box sx={{ flexBasis: 250, flexGrow: 1 }}>
				<DateTimeInput onChange={onChangePeriod(1)} label="" value={parsed_value[1]} />
			</Box>
		</Box>
	)
}

function DateField({ field, onChangeAndSave, value, onBlur, disabled, finished }) {
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || "Sin respuesta"}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<DateInput value={value} onChange={onChangeAndSave} label="" />
		</Box>
	)
}

function NumberField({ field, onChange, value, onBlur, disabled, finished }) {
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || "Sin respuesta"}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<TextInput type="number" onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}


function GeneralField({ field, updateField, finished }) {
	let ToRender = TextField
	if (field.field_type === "text") { ToRender = TextField }
	if (field.field_type === "rut") { ToRender = RutField }
	if (field.field_type === "select") { ToRender = SelectField }
	if (field.field_type === "multiselect") { ToRender = MultiField }
	if (field.field_type === "rte") { ToRender = RTEField }
	if (field.field_type === "img") { ToRender = ImageField }
	if (field.field_type === "compliance") { ToRender = ComplianceField }
	if (field.field_type === "number") { ToRender = NumberField }
	if (field.field_type === "duration") { ToRender = PeriodField }
	if (field.field_type === "date") { ToRender = DateField }

	const [value, setValue] = useState(field.value)

	useEffect(() => {
		setValue(field.value)
	}, [field])

	function onChange(event) {
		const { target } = event
		setValue(target.value)
	}

	async function onBlur(e, forced_value = null) {
		const new_value = !!forced_value && typeof forced_value === "string" ? forced_value : value
		const body = { ...field, value: new_value }
		updateField(body)
	}

	async function onChangeAndSave(event) {
		const { target } = event
		setValue(target.value)
		const body = { ...field, value: target.value }
		updateField(body)
	}

	return (
		<Box sx={css.field} >
			<Typography variant='subtitle1'><strong>{`${field.label}${field?.required ? " *" : ""}`}</strong></Typography>
			<ToRender finished={finished} onBlur={onBlur} field={field} value={value} onChange={onChange} onChangeAndSave={onChangeAndSave} updateField={updateField} />
		</Box>
	)
}

export default GeneralField