import React, { Component } from 'react'
import { Dialog, DialogContent, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import { Box, Fab, IconButton, Typography } from '@mui/material'
import { RotateRight } from '@material-ui/icons'

const style = () => ({
  img: {
    borderRadius: "50%"
  },
  floating: {
    position: 'fixed',
    top: 24,
    right: 24
  }
})
class ImageContainer extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      angle: 0,
    }

    autobind(ImageContainer, this)
  }

  async componentDidMount() {
    const { src } = this.props
    let image = src
    // Check if base64
    if (image.includes("data:image")) {
      image = src.split("|&|")[0]
    }
  }

  async componentDidUpdate(prevProps) {
    const { src } = this.props
    let image = src

    if (image.includes("data:image")) {
      image = src.split("|&|")[0]
    }
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleRotate() {
    const { angle } = this.state
    const new_angle = (angle + 90) % 360
    this.setState({ angle: new_angle })
  }

  render() {
    const { classes, src, width, height, alt, border } = this.props
    const { open, angle } = this.state
    const extension = src.split(".")[src.split(".").length - 1].toLowerCase()

    let image = src
    // Check if base64
    if (image.includes("data:image")) {
      image = src.split("|&|")[0]
    }

    // const images = ["png", "jpg", "jpeg"]
    const videos = ["mp4", "ogg"]
    const isVideo = videos.includes(extension)

    return (
      <>
        {!isVideo ?
          <Box sx={{ maxWidth: width, maxHeight: height, overflow: 'hidden', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img src={image} width={width} alt={alt} onClick={this.handleOpen} style={{ borderRadius: border }} />
          </Box>
          :
          <video width={width} height={height} onClick={this.handleOpen} className={classes.img}>
            <source src={src} type={`video/${extension}`} />
          </video>
        }
        <Dialog open={open} onClose={this.handleOpen}>
          <Box className={classes.floating}>
            <Fab onClick={this.handleRotate}>
              <RotateRight />
            </Fab>
          </Box >
          <Box style={{ overflow: 'auto' }}>
            <Box style={{ transform: `rotate(${angle}deg)` }}>
              {!isVideo ?
                <img src={image} alt={alt} width="100%" />
                :
                <video controls width="100%">
                  <source src={src} type={`video/${extension}`} />
                </video>
              }
            </Box>
          </Box>
        </Dialog>
      </>
    )
  }
}

ImageContainer.defaultProps = {
  heigth: 64,
  width: 64,
  src: "",
  alt: "alt",
  border: '50%'
}

export default withStyles(style)(ImageContainer)