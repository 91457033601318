import instance2 from "../Utils/instance2"

export function getSettingsWorkflows(body) {
  return instance2.get("settings/workflows/get.php", { params: body })
}

export function createSettingsWorkflow(body) {
  return instance2.post("settings/workflows/create.php", body)
}

export function editSettingsWorkflow(body) {
  return instance2.post("settings/workflows/edit.php", body)
}

export function createWorkflowStep(body) {
  return instance2.post("settings/workflows/create_step.php", body)
}

export function createWorkflowPhase(body) {
  return instance2.post("settings/workflows/create_phase.php", body)
}

export function editWorkflowStep(body) {
  return instance2.post("settings/workflows/edit_step.php", body)
}

export function editWorkflowPhase(body) {
  return instance2.post("settings/workflows/edit_phase.php", body)
}

export function deleteWorkflowPhase(body) {
  return instance2.post("settings/workflows/delete_phase.php", body)
}

export function deleteWorkflowStep(body) {
  return instance2.post("settings/workflows/delete_step.php", body)
}

export function editPhase(body) {
  return instance2.post("workflows/edit_phase.php", body)
}

export function editStep(body) {
  return instance2.post("workflows/edit_step.php", body)
}

export function editWorkflow(body) {
  return instance2.post("workflows/edit.php", body)
}

export function createWorkflow(body) {
  return instance2.post("workflows/create.php", body)
}

export function sendMail(body) {
  return instance2.post("workflows/notification.php", body)
}

export function createInjured(body) {
  return instance2.post("workflows/create_injured_users.php", body)
}

export function editInjured(body) {
  return instance2.post("workflows/edit_injured_users.php", body)
}

export function deleteInjured(body) {
  return instance2.post("workflows/delete_injured_users.php", body)
}

export function getWorkflows(body) {
  return instance2.get("workflows/get", { params: body })
}

export function createWorkflowFinding(body) {
  return instance2.post("workflows/finding", body)
}

export function createWorkflowChecklist(body) {
  return instance2.post("workflows/checklist", body)
}

export function assignWorkflowChecklist(body) {
  return instance2.put("workflows/checklist", body)
}

export function deleteBaseWorkflow(body) {
  return instance2.delete("settings/workflows", { data: body })
}

export function deleteWorkflow(body) {
  return instance2.delete("workflows", { data: body })
}

export function downloadWorkflow(body) {
  return instance2.get("workflows/download", { params: body, responseType: 'blob' })
}