import { createSubBranch, getSubBranches } from "../../API/branches"
import { createPosition } from "../../API/users"
import { getPositions } from "../../API/positions"
export const userInfo = [
    {
        name: "userTypeId",
        label: "Tipo de usuario",
        type: "select",
        options: [
            { value: "1", label: "Admin" },
            { value: "2", label: "Supervisor" },
            { value: "3", label: "Colaborador" },
        ],
        required: true,
        format: (value) => value.toUpperCase(),
    },
    { name: "email", label: "Correo", required: true, },
    { name: "firstname", label: "Nombre", required: true, },
    { name: "lastname", label: "Apellido", required: true, },
    { name: "rut", label: "RUT", required: true, type: "rut" },
    { name: "phone", label: "Teléfono", required: true, },
    { name: "address", label: "Dirección", required: true, },
    { name: "dateBirth", label: "Fecha de Nacimiento", type: "date", required: true, },
    {
        name: "position_id",
        label: "Cargo",
        required: true,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar cargo",
        onFetch: getPositions,
        onCreate: createPosition
    },
    {
        name: "user_id",
        label: "Supervisor",
        type: "select",
        options: [],
        placeholder: "Seleccionar Supervisor",
    },
    {
        name: "branch_ids",
        label: "Sucursal(es)",
        type: "multiselect",
        options: [],
        placeholder: "Seleccionar Sucursal",
        required: true,
    },
    {
        name: "sub_branch_id",
        label: "Empresa contratista",
        required: false,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar empresa contratista",
        onFetch: getSubBranches,
        onCreate: createSubBranch
    },
    {
        name: "sub_enterprise_id",
        label: "Empresa",
        type: "select",
        options: [],
        placeholder: "Seleccionar Empresa",
    },
    { name: "date_entry", label: "Fecha de ingreso", required: false, type: "date" },
    { name: "nationality", label: "Nacionalidad", required: false, },
    { name: "profession", label: "Profesión / Oficio", required: false, },
    { name: "emergency_contact", label: "Contacto de emergencia", required: false, },
    { name: "emergency_phone", label: "Teléfono de emergencia", required: false, },
]

export const userEditInfo = [
    { name: "email", label: "Correo" },
    { name: "firstname", label: "Nombre" },
    { name: "lastname", label: "Apellido" },
    { name: "rut", label: "RUT" },
    { name: "phone", label: "Teléfono" },
    { name: "address", label: "Dirección" },
    { name: "dateBirth", label: "Fecha de Nacimiento", type: "date" },
    {
        name: "position_id",
        label: "Cargo",
        required: true,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar cargo",
        onFetch: getPositions,
        onCreate: createPosition
    },
    {
        name: "userTypeId",
        label: "Tipo de usuario",
        type: "select",
        options: [
            { value: "1", label: "Admin" },
            { value: "2", label: "Supervisor" },
            { value: "3", label: "Colaborador" },
        ],
    },
    // {
    //     name: "branch_id",
    //     label: "Sucursal",
    //     type: "select",
    //     options: [],
    // },
    {
        name: "branch_ids",
        label: "Centro de trabajo",
        type: "multiselect",
        options: [],
    },
    {
        name: "sub_branch_id",
        label: "Empresa contratista",
        required: false,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar empresa contratista",
        onFetch: getSubBranches,
        onCreate: createSubBranch
    },
    {
        name: "user_id",
        label: "Supervisor",
        type: "select",
        options: [],
    },
    {
        name: "sub_enterprise_id",
        label: "Empresa",
        type: "select",
        options: [],
        placeholder: "Seleccionar Sub Empresa",
    },
    { name: "date_entry", label: "Fecha de ingreso", required: false, type: "date" },
    { name: "nationality", label: "Nacionalidad", required: false, },
    { name: "profession", label: "Profesión / Oficio", required: false, },
    { name: "emergency_contact", label: "Contacto de emergencia", required: false, },
    { name: "emergency_phone", label: "Teléfono de emergencia", required: false, },
]

export const pwdForm = [
    { name: "newpassword", label: "Contraseña Nueva", type: "password" },
    {
        name: "newpasswordconfirm",
        label: "Confirmación de Contraseña",
        type: "password",
    },
]

export const userTableInfo = [
    { name: "Nombre", label: "name", sortable: true },
    { name: "Fecha de ingreso", label: "date_entry", sortable: true },
    { name: "RUT", label: "rut", sortable: true },
    { name: "Empresa", label: "sub_branch", format: (value, element) => value || element.enterprise },
    { name: "Centro de trabajo", label: "branch" },
    { name: "Cargo", label: "position" },
    { name: "Correo", label: "email", sortable: true },
]

export const adminPermissionList = [
    {
        section: "Administración", actions: [
            { name: "SuperAdmin (Activar y Desactivar permisos)", label: "sa" }
        ]
    },
    {
        section: "Actividades", actions: [
            { name: "Planificar actividades", label: "pact" },
            { name: "Editar actividades", label: "eact" }
        ]
    },
    {
        section: "Empresa", actions: [
            { name: "Crear centro de trabajo", label: "cbr" },
            { name: "Crear grupos de centro de trabajo", label: "ebrt" },
            { name: "Asociar grupos a centros de trabajo", label: "abrt" },
            { name: "Enviar campaña de correos", label: "ecc" },
        ],
    },
    {
        section: "Cargos", actions: [
            { name: "Crear cargo", label: "c_position" },
            { name: "Editar cargo", label: "e_position" },
            { name: "Eliminar cargo", label: "d_position" },
        ]
    },
    {
        section: "Colaboradores", actions: [
            { name: "Crear colaborador", label: "cw" },
            { name: "Editar colaborador", label: "ew" }
        ]
    },
    {
        section: "Registros y formularios", actions: [
            { name: "Crear registros y formularios", label: "cch" },
            { name: "Eliminar registros y formularios", label: "dch" },
        ]
    },
    {
        section: "Hallazgos", actions: [
            { name: "Aprobar y rechazar hallazgos", label: "arf" },
        ]
    },
    {
        section: "Reportes", actions: [
            { name: "Crear/Editar Gráficos", label: "cgc" },
        ]
    }
]

export const supervisorPermissionList = [
    {
        section: "Colaboradores", actions: [
            { name: "Crear colaborador", label: "cw" },
        ]
    },
    {
        section: "Registros y formularios", actions: [
            { name: "Crear registros y formularios", label: "cch" },
        ]
    },
    {
        section: "Hallazgos", actions: [
            { name: "Aprobar y rechazar hallazgos", label: "arf" },
        ]
    },
]

export const permissions_dict = {
    "create_activity": "pact",
    "edit_activity": "eact",
    "create_branch": "cbr",
    "send_email_campaign": "ecc",
    "create_position": "c_position",
    "edit_position": "e_position",
    "delete_position": "d_position",
    "create_tag": "ctag",
    "edit_tag": "etag",
    "delete_tag": "dtag",
    "create_worker": "cw",
    "edit_worker": "ew",
    "create_checklist": "cch",
    "delete_checklist": "dch",
    "approve_findings": "appf",
    "super_admin": "sa",
    "create_branch_tag": "cbrt",
    "assign_branch_tag": "abrt",
}